import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.VUE_APP_SIGNUP,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.VUE_APP_AUTHORITY,
    },
  },
  authorityDomain: process.env.VUE_APP_AUTHORITY_DOMAIN,
};

export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.VUE_APP_REDIRECT,
    postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
