<template>
  <div id="app">
    <!--<nav-bar v-if="!$route.meta.hideNavbar"></nav-bar>-->
    <router-view></router-view>
  </div>
</template>

<script>
//import NavBar from "./components/NavBar.vue";
let appConfig = {
  title: "Trustcore Portal",
  description: "Trustcore Portal",
};
export default {
  name: "App",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  components: {
    //NavBar
  },
};
</script>
<style>
/**@import "https://stackpath.bootstrapcdn.com/bootstrap/4.1.2/css/bootstrap.min.css";*/
</style>
