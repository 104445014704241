export const PERMISSIONS = {
    ORG:{
        CREATE: "org_create",
        READ: "org_read",
        UPDATE: "org_update",
        DELETE: "org_delete"
    },
    ROLE:{
        CREATE: "role_create",
        READ: "role_read",
        UPDATE: "role_update",
        DELETE: "role_delete"
    },
    USER:{
        CREATE: "user_create",
        READ: "user_read",
        UPDATE: "user_update",
        DELETE: "user_delete"
    },
    DEVICE:{
        CREATE: "device_create",
        READ: "device_read",
        UPDATE: "device_update",
        DELETE: "device_delete"
    },
    DEVICEGROUP:{
        CREATE: "device_group_create",
        READ: "device_group_read",
        UPDATE: "device_group_update",
        DELETE: "device_group_delete"
    },
    SUPERADMIN: {
        ROLE: "role_superadmin",
        DASHBOARD:{
            READ: "superadmin_dashboard_read"
        }
    },
    ORGADMIN: {
        ROLE: "role_orgadmin",
        DASHBOARD:{
            READ: "org_dashboard_read"
        }
    }
}